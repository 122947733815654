<template>
    <div><Icon type="ios-infinite-outline" /></div>
</template>

<script>
    export default {
    }
</script>

<style scoped lang="less">

    div {
        text-align: center;
    }

</style>